import React, { useState } from "react";
import "./Concept.css";
import Cozy from "../../assets/cozy.jpeg";
import Aut from "../../assets/authe.jpeg";

function Concept() {
  return (
    <div className="concept-tab">
      <section className="content-area">
        <div className="restaurant-description">
          <section className="header-area">
            <h1>Welcome to Our Italian Family</h1>
            <p>Discover the heart of Italy with every dish at Amor Di Pasta.</p>
          </section>
          <h2>Authentic Italian Flavors</h2>
          <p>
            Nestled in the heart of your city, Amor Di Pasta represents a
            generations-long passion for authentic Italian cuisine. Our roots
            run deep with traditional recipes passed down through the years,
            served up with a modern twist. Each meal is a celebration of
            flavors, and our vegan Italian menu offers the same richness to
            delight all palates.
          </p>
          <p>
            Indulge in handcrafted pizzas, freshly made pastas, and an array of
            gnocchi and gluten-free options that promise an unforgettable dining
            experience. Our vegan dishes are crafted with the same care,
            ensuring that every guest can savor the true essence of Italian
            dining.
          </p>
          <section className="vegan-planet-section">
            <h2>Vegan Planet - Our Plant-Based Italian Journey</h2>
            <p>
              At Amor Di Pasta, our passion for Italian culinary traditions
              embraces the plant-based revolution. "Vegan Planet" is our
              dedicated vegan menu, where every dish is a testament to the
              possibilities of vegan Italian cooking. We've reimagined classic
              Italian favorites with innovative twists, bringing you a
              plant-based menu that doesn't compromise on the authentic flavors
              you love.
            </p>
          </section>
          <button
            className="cta-button"
            onClick={() => (window.location.href = "/reservations")}
          >
            Reserve Your Table Today
          </button>
        </div>

        <div className="image-gallery">
          <img src={Cozy} alt="Cozy interior of Amor Di Pasta" />
        </div>
      </section>
    </div>
  );
}

export default Concept;

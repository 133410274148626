import React from "react";
import { Link } from "react-router-dom";

export default ({ close }) => (
  <div className="menu">
    <ul>
      <li onClick={close}>
        <Link to="/">Home</Link>
      </li>
      <li onClick={close}>
        <Link to="/concept">Concept</Link>
      </li>
      <li onClick={close}>
        <Link to="/reservations">Reservations</Link>
      </li>
      <li onClick={close}>
        <Link to="/menus">Menus</Link>
      </li>
      {/*}   <li onClick={close}>
        <Link to="/press">Press</Link>
</li> */}
    </ul>
  </div>
);

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Reservation.css";
import axios from "axios";
import { Helmet } from "react-helmet";

function Reservation() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    partyNumber: "",
    date: "",
    timeSlot: "",
    message: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://amordipastaback.onrender.com/sendEmail",
        formData
      );

      console.log("Confirmation email sent successfully!", response.data);
      setFormSubmitted(true); // Set the flag to show the alert

      // Reset the form state
      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        partyNumber: "",
        date: "",
        message: "",
      });

      // Optionally, set a timeout to hide the alert after a few seconds
      setTimeout(() => setFormSubmitted(false), 5000);
    } catch (error) {
      console.error("Error sending confirmation email:", error.message);
      alert("There was an error sending your form. Please try again.");
    }
  };

  return (
    <>
      <div className="reserv-container">
        <Helmet>
          <title>Reservations</title>
          <meta
            name="description"
            content="Reservations for table seating can be made on our website ..."
          />
        </Helmet>
        <section className="reserve">
          <a href="/reserve">
            <button className="res-button">BOOK NOW</button>
          </a>
        </section>
        <section className="contact-info">
          <h2>
            <a href="tel:+13056405760">(305) 640-5760</a>
          </h2>

          <div className="large-party-info">
            <p>
              Secure your table reservation by clicking the button above. If
              you're planning a private event or your party exceeds 10 guests,
              we welcome you to get in touch with us directly at the provided
              number, or simply complete the form below to inquire further
            </p>
          </div>
        </section>
        <section className="reservation-form">
          <h3>Reservation Details</h3>
          {formSubmitted && (
            <div className="alert">
              Thank you for your reservation. We will be back to you shortly.
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                autoFocus={false}
                className="form-input"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                autoFocus={false}
                className="form-input"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <input
                type="tel"
                placeholder="Phone Number"
                name="phoneNumber"
                autoFocus={false}
                className="form-input"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              <input
                type="email"
                placeholder="Email"
                name="email"
                autoFocus={false}
                className="form-input"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <input
                type="number"
                placeholder="Party Number"
                name="partyNumber"
                autoFocus={false}
                className="form-input"
                min="1"
                value={formData.partyNumber}
                onChange={handleChange}
              />
              <input
                type="date"
                placeholder="Date"
                name="date"
                autoFocus={false}
                className="form-input"
                value={formData.date}
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <textarea
                placeholder="Message (Optional)"
                name="message"
                className="form-textarea"
                autoFocus={false}
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              <input
                type="text"
                placeholder="Time"
                name="timeSlot"
                autoFocus={false}
                className="form-input"
                value={formData.timeSlot}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="form-submit">
              Submit
            </button>
          </form>
        </section>
      </div>
    </>
  );
}

export default Reservation;

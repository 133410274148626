import React from "react";
import "./Calendar.css";

const ThankYou = () => {
  return (
    <div className="thank-you-page">
      <h1>Thank You for Your Reservation!</h1>
      <p>
        We look forward to welcoming you to our restaurant. You should receive
        an email shortly.
      </p>
    </div>
  );
};

export default ThankYou;

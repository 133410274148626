import React, { useState, useEffect } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logoAmor.png";
import Vegan from "../../assets/VeganPlanetLogo.png";
import Popup from "reactjs-popup";
import BurgerIcon from "../burgerIcon";
import Menu from "../Menu";

function Header() {
  const contentStyle = {
    background: "rgba(255,255,255,0",
    width: "80%",
    border: "none",
  };

  const [activeTab, setActiveTab] = useState(window.location.pathname);
  const [isSticky, setIsSticky] = useState(true); // Set isSticky to true initially
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeader = () => {
    // Check the scroll direction
    if (window.scrollY < lastScrollY) {
      // If scrolling up
      setIsSticky(true);
    } else if (window.scrollY > 100) {
      // If scrolling down and past a certain threshold
      setIsSticky(false);
    }
    // Update the last scroll position
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlHeader);

    return () => {
      window.removeEventListener("scroll", controlHeader);
    };
  }, [lastScrollY]);

  return (
    <>
      <header className={`header ${isSticky ? "sticky" : ""}`}>
        <div class="logo-container">
          <div class="logo">
            <img src={logo} alt="Logo 1" />
          </div>
          <div class="logo">
            <img src={Vegan} alt="Logo 2" />
          </div>
        </div>
        <nav className="menuu">
          <ul>
            <li className={activeTab === "/" ? "active" : ""}>
              <a href="/" onClick={() => setActiveTab("/")}>
                Home
              </a>
            </li>
            <li className={activeTab === "/concept" ? "active" : ""}>
              <a href="/concept" onClick={() => setActiveTab("/concept")}>
                Concept
              </a>
            </li>
            <li className={activeTab === "/menus" ? "active" : ""}>
              <a href="/menus" onClick={() => setActiveTab("/menus")}>
                Menus
              </a>
            </li>
            <li className={activeTab === "/reservations" ? "active" : ""}>
              <a
                href="/reservations"
                onClick={() => setActiveTab("/reservations")}
              >
                Reservations
              </a>
            </li>
            {/*}  <li className={activeTab === "/press" ? "active" : ""}>
              <a href="/press" onClick={() => setActiveTab("/press")}>
                Press
              </a>
  </li> */}
          </ul>
        </nav>
      </header>
      <div className="header-placeholder"></div>
      <Popup
        modal
        overlayStyle={{ background: "rgba(255,255,255,0.98" }}
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        trigger={(open) => <BurgerIcon open={open} />}
      >
        {(close) => <Menu close={close} />}
      </Popup>
    </>
  );
}
export default Header;

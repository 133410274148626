import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./component/header/header";
import Home from "./container/Home/Home";
import Footer from "./component/footer/footer";
import Reservation from "./container/Reservation/Reservation";
import Concept from "./container/Concept/Concept";
import Calendar from "./component/Calendar/Calendar";
import Reserved from "./component/Calendar/Reserved";
import ScrollToTop from "./ScrollToTop";
import Menu from "./container/Menu/Menu";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/menus" element={<Menu />} />
        <Route path="/reserved" element={<Reserved />} />
        <Route path="/reserve" element={<Calendar />} />
        <Route path="/concept" element={<Concept />} />
        <Route path="/reservations" element={<Reservation />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

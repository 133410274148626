import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapView = () => {
  const position = [25.8113561, -80.3530334];

  const handleMarkerClick = () => {
    const googleMapsUrl = `https://www.google.com/maps/place/Amor+di+Pasta+Doral/@25.8112211,-80.3502599,15z/data=!4m6!3m5!1s0x88d9b9e02539c28f:0xf876850ddff91e1!8m2!3d25.8112211!4d-80.3502599!16s%2Fg%2F11vwv76rrr?entry=ttu`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <MapContainer
      center={position}
      zoom={15}
      style={{ height: "300px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} eventHandlers={{ click: handleMarkerClick }}>
        <Popup>
          Amor Di Pasta Doral
          <br />
          9420 NW 41st St, Doral, FL 33178
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapView;

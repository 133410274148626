import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapView = () => {
  const position = [25.7993888, -80.1910761];

  const handleMarkerClick = () => {
    const googleMapsUrl = `https://www.google.com/maps/place/Amor+Di+Pasta/@25.7992839,-80.193682,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9b6abb81b9ebb:0xae4794e073a47dbf!8m2!3d25.7992839!4d-80.1911071!16s%2Fg%2F11h9rbwyfp?entry=ttu`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <MapContainer
      center={position}
      zoom={15}
      style={{ height: "300px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} eventHandlers={{ click: handleMarkerClick }}>
        <Popup>
          Amor Di Pasta
          <br />
          2320 NE 2nd Ave, Miami, FL 33137
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapView;
